import React from "react"
import Styles from "./css/footerlinks.module.scss"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"

class Footer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      display: "block",
      unsubscribe: false,
      language: "de"
    }
    if(props.data){
      this.state.data = props.data;
    }
    if(props.unsubscribe){
      this.setState({unsubscribe: props.unsubscribe});
    }
  }
  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }
  componentDidMount() {
    this.setState({language: this.props.language});
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
  }
  render() {
    let unsubscribe = null;
    if(this.state.unsubscribe){
      unsubscribe = <span> | <a href="/unsubscribe/" target="_blank">Unsubscribe</a></span>;
    }
    let footertext = <span>Tightrope Interactive | <a href="/terms-of-service/" target="_blank">Terms</a> | <a href="/privacy-policy/" target="_blank">Privacy</a> | <a href="/uninstall/" target="_blank">Uninstall</a>{unsubscribe} | <a href="/contact-us/" target="_blank">Contact</a> <br />All trademarks are property of their respective owners <br /> <a href="/privacy-policy/#cali-consumer" target="_blank">Do Not Sell My Personal Information</a></span>;
    if(this.state.language == 'de'){
      footertext = <span>Tightrope Interactive | <a href="/terms-of-service/" target="_blank">Bedingungen</a> | <a href="/privacy-policy/" target="_blank">Datenschutz</a> | <a href="/uninstall/" target="_blank">Deinstallieren</a> | <a href="/unsubscribe/" target="_blank">Abbestellen</a> | <a href="/contact-us/" target="_blank">Kontakt</a> <br />Warenzeichen sind Eigentum ihrer jeweiligen Inhaber  <br /> <a href="/privacy-policy/#cali-consumer" target="_blank">Verkaufen Sie meine persönlichen Daten nicht</a></span>;
    }
    if(this.state.language == 'fr'){
      footertext = <span>Tightrope Interactive | <a href="/terms-of-service/" target="_blank">Conditions</a> | <a href="/privacy-policy/" target="_blank">Confidentialité </a> | <a href="/uninstall/" target="_blank">Désinstaller </a> | <a href="/unsubscribe/" target="_blank">Se désabonner </a> | <a href="/contact-us/" target="_blank">Contact</a> <br />Toutes les marques déposées sont la propriété de leurs propriétaires respectifs   <br /> <a href="/privacy-policy/#cali-consumer" target="_blank">Ne vendez pas mes informations personnelles</a></span>;
    }
    if(this.state.language == 'es'){
      footertext = <span>Tightrope Interactive | <a href="/terms-of-service/" target="_blank">Condiciones</a> | <a href="/privacy-policy/" target="_blank">Privacidad </a> | <a href="/uninstall/" target="_blank">Desinstalar </a> | <a href="/unsubscribe/" target="_blank">Darse de baja </a> | <a href="/contact-us/" target="_blank">Contacto</a> <br />Todas las marcas comerciales son propiedad de sus respectivos dueños. <br /> <a href="/privacy-policy/#cali-consumer" target="_blank">No vender mi información personal</a></span>;
    }
    if(this.state.language == 'italian'){
      footertext = <span>Tightrope Interactive | <a href="/terms-of-service/" target="_blank">Termini</a> | <a href="/privacy-policy/" target="_blank">Privacy</a> | <a href="/uninstall/" target="_blank">Disinstallare</a> | <a href="/contact-us/" target="_blank">Contatto</a> <br />Tutti i marchi appartengono ai rispettivi proprietari<br /> <a href="/privacy-policy/#cali-consumer" target="_blank">Non vendere le mie informazioni personali</a></span>;
    }
    if(this.state.language == 'swedish'){
      footertext = <span>Tightrope Interactive | <a href="/terms-of-service/" target="_blank">Användarvillkor</a> | <a href="/privacy-policy/" target="_blank">Sekretesspolicy</a> | <a href="/uninstall/" target="_blank">Avinstallera</a> | <a href="/contact-us/" target="_blank">Kontakta</a> <br />Alla varumärken tillhör sina respektive ägare<br /> <a href="/privacy-policy/#cali-consumer" target="_blank">Sälj inte min personliga information</a></span>;
    }
    if(this.state.language == 'finnish'){
      footertext = <span>Tightrope Interactive | <a href="/terms-of-service/" target="_blank">Palveluehdot</a> | <a href="/privacy-policy/" target="_blank">Tietosuojakäytäntö</a> | <a href="/uninstall/" target="_blank">Poista asennus</a> | <a href="/contact-us/" target="_blank">Ota yhteyttä</a> <br />Kaikki tavaramerkit ovat omistajiensa omaisuutta<br /> <a href="/privacy-policy/#cali-consumer" target="_blank">Älä myy henkilökohtaisia tietojani</a></span>;
    }
    if(this.state.language == 'danish'){
      footertext = <span>Tightrope Interactive | <a href="/terms-of-service/" target="_blank">Servicevilkår</a> | <a href="/privacy-policy/" target="_blank">Fortrolighedspolitik</a> | <a href="/uninstall/" target="_blank">Afinstaller</a> | <a href="/contact-us/" target="_blank">Kontakt</a> <br />Alle varemærker tilhører deres respektive ejere<br /> <a href="/privacy-policy/#cali-consumer" target="_blank">Sælg ikke mine personlige oplysninger</a></span>;
    }
    if(this.state.language == 'norwegian'){
      footertext = <span>Tightrope Interactive | <a href="/terms-of-service/" target="_blank">Vilkår for bruk</a> | <a href="/privacy-policy/" target="_blank">Personvernerklæring</a> | <a href="/uninstall/" target="_blank">Avinstaller</a> | <a href="/contact-us/" target="_blank">Kontakt</a> <br />Alle varemerker tilhører sine respektive eiere<br /> <a href="/privacy-policy/#cali-consumer" target="_blank">Ikke selg min personlige informasjon</a></span>;
    }
    if(this.state.language == 'dutch'){
      footertext = <span>Tightrope Interactive | <a href="/terms-of-service/" target="_blank">Servicevoorwaarden</a> | <a href="/privacy-policy/" target="_blank">Privacybeleid</a> | <a href="/uninstall/" target="_blank">Verwijderen</a> | <a href="/contact-us/" target="_blank">Contact</a> <br />Alle handelsmerken zijn eigendom van hun respectievelijke eigenaren<br /> <a href="/privacy-policy/#cali-consumer" target="_blank">Verkoop mijn persoonlijke gegevens niet</a></span>;
    }
    return (
      <div className ={Styles.br} style={{ display: this.state.display }}>
        {footertext}
      </div>
    )
  }
}

export default Footer
