import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Fflp from "src/components/fflp"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import Disclaimers from "@tightrope/disclaimers"
import ChunkyFooter from "src/components/chunkyfooter"
import RawFooter from "@tightrope/footerlinks/rawfooter.js"

const footerData = import("./data/footerData.json");
const fflpData = import("./data/data.json");

export default function deBMspb() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
     .footerlinks-module--br {
      display: none!important;
    }
       body {
         height: 100vh;
         width: 100%;
         min-width: 728px;
         min-height: 700px;
         background: #E1EEF8;
         background: linear-gradient(to top right, #E1EEF8, #F7FCFF);
         padding: 0;
         margin: 0;
         background-repeat: no-repeat;
         background-size: cover;
         font-family: 'Roboto', sans-serif;
       }

       .btf_cb-module--btfCb button.btf_cb-module--install {
          border: none;
          background: #0078D4;
          color: #fff;
          font-weight: 500;
          font-size: 20pt;
          padding: 15px 50px;
          border-radius: 50px;
          cursor: pointer;
          letter-spacing: .5px;
          transition: .2s ease;
          margin: 25px auto;
          max-width: 300px;
       }

       #fflp-module--fflp {
         height: 100vh;
       }

       #fflp-module--fflp .fflp-module--main {
         margin: 100px auto 0;
       }

       #fflp-module--fflp button.fflp-module--download {
         margin: 40px 0;
       }
       #disclaimers-module--disclaimers {
        margin:28px 0 0 0;
        width:100%;
        }
  
       #disclaimers-module--disclaimers p {
        font-weight: 300;
        color: #333;
        font-size: 13px;
        text-align: center;
        font-family: Roboto,sans-serif;
        max-width: 700px;
        margin: 0 auto;
        }
  
        #disclaimers-module--disclaimers p a {
        font-weight:700;
        }
       footer {
        background:#fff;
        font-family: 'Roboto', sans-serif !important;
        display: block;
        position: relative;
        color: #333;
        border-radius: 5px;
        width: 100%;
      }
      footer ul {
        position: relative;
        list-style: none;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: right;
        color: #333;
        font-size: 12px;
        padding: 12px 0;
      }
      footer ul li {
        padding: 0 4px 0 0;
      }
      footer ul li:last-child {
        padding: 0;
      }
      footer ul li:after {
        content: '|';
        padding: 0 0 0 4px;
      }
      footer ul li:nth-child(6):after, footer ul li:nth-child(7):after, footer ul li:last-child:after {
        content: none;
        padding: 0;
      }
      footer ul a {
        color: #333;
      }
      @media only screen and (max-width: 768px) {
    
        footer {
          position: relative;
          display: flex;
          justify-content:center;
          align-items:center;
          color: #333;
          width: 100%;
          right:auto;
          bottom:auto;
      }
    
      footer ul {
        position: relative;
        display: flex;
        justify-content:center;
        align-items:center;
    }
    `}
    </style>
    <style type="text/css">
         </style>
    <title>Print any web page easily - Easy Print</title></Helmet>
    <section>
      <Fflp data={fflpData} language='de'>
      <Disclaimers language='de-firefox'></Disclaimers>
      </Fflp>
      <ChunkyFooter data={footerData}><Disclaimers language='de-firefox'></Disclaimers></ChunkyFooter>
      </section>
      <RawFooter language='de'></RawFooter>
    </HomepageLayout>
  )
}
